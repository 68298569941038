.hero-bottom-container {
    display: flex;
    flex-direction: column;
    margin-top: 7rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-bottom: 4rem;
}

.description-container {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.services-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 6rem;
}

.bottom-image-container {
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-bottom: 4rem;
}

.bottom-image-title {
    text-align: center;
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    height: fit-content;
    width: 100%;
    padding-bottom: 1rem;
}

.bottom-image {
    width: 6.5rem;
    margin-bottom: 4rem;
}

.horizontal-line {
    width: 100%;
    height: 0.15rem;
    background-color: white;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
    .hero-bottom-container {
        display: flex;
        margin-top: 7rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding-bottom: 4rem;
    }
    .description-container {
        width: 100%;
        margin-right: 10rem;
        text-align: center;
    }
    .services-container {
        margin-top: 4rem;
        flex-direction: row;
        margin-bottom: 1rem;
    }
    .bottom-image-title {
        height: 3rem;
        font-size: 1rem;
        width: 100%;
        padding-bottom: 0;
    }
    .bottom-image {
        width: 5rem;
        margin-bottom: 0;
    }
    .bottom-image-container {
        margin-top: -1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
    }
}

@media only screen and (min-width: 1024px) {
    .hero-bottom-container {
        display: flex;
        flex-direction: row;
        margin-top: 7rem;
        margin-left: 7%;
        padding-bottom: 4rem;
    }
    .description-container {
        width: 30%;
        margin-right: 10rem;
        text-align: left;
    }
    .services-container {
        flex-direction: row;
        width: 60%;
        justify-content: center;
        margin-top: 0rem;
        margin-bottom: 0;
    }
    .bottom-image-container {
        margin-top: -1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        margin-right: 5rem;
    }
    .bottom-image {
        width: 6.2rem;
    }
    .bottom-image-title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        height: 3rem;
        width: 100%;
        padding-bottom: 0;
    }
}