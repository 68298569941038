body.modal-active{
    overflow: hidden;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    animation: modalopen 0.5s forwards;
}

.modal.active {
    display: block;
}

.modal-content {
    margin: 5% auto;
    width: 90%;
    height: fit-content;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    animation: modalopen 0.5s;
}

.modal-header {
    //padding: 0.2rem 0.4rem;
    background: $primary;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    height: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal-body {
    padding: 0.2rem 0.4rem;
    background: $primary;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
    height: 22rem;
    display: flex;
    flex-direction: column;
}

.modal-content-title {
    @extend .title;
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.modal-content-text {
    margin: 0px;
    color: white;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
}

.modal-content-button {
    margin-top: auto;
    margin-bottom: 2rem;
    background-color: $secondary;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 0.7rem;
    width: 100%;
    transition: 0.2s;
}

.modal-content-button:hover {
    background-color: $secondary-accent;
    transition: 0.2s;
    cursor: pointer;
}

.modal-content-button:focus {
    outline: none;
}

.modal-close {
    height: 60%;
    margin-right: 1.5rem;
    margin-top: 1.5rem;

    animation: grow 1s alternate-reverse infinite;
}

.modal-close:hover {
    cursor: pointer;
}

@keyframes grow {
    100% {
        transform: scale(1.2);
    }
}

@keyframes modalopen {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

$tabletMinWidth: "768px";
@media only screen and (min-width: $tabletMinWidth) {
    .modal-content {
        width: 80%;
    }
    .modal-body {
        padding: 1rem 2rem;
        padding-left: 15%;
        padding-right: 15%;
    }
}

$desktopMinWidth: "1024px";
@media only screen and (min-width: $desktopMinWidth) {
    .modal-content {
        margin: 10% auto;
        width: 60%;
    }

    .modal-body {
        padding: 1rem 2rem;
        padding-left: 15%;
        padding-right: 15%;
    }
}

#privacy-modal {
    .modal-content {
        margin: 2% auto;
    }

    .modal-body {
        padding: 1rem 2rem 3rem 2rem;
        height: auto;

    }

    .modal-content-text {
        text-align: left;
        font-size: 0.9rem;
    }
}