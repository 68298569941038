.footer-container{
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logos{
    margin-top: 2rem;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between;
}

.footer-image{
    height: 100%;
}


.footer-image.brand-logo {
    width: 6rem;
}

.footer-image.dhl-logo {
    width: 7.5rem;

}

.footer-image.stripe-logo {
    width: 6rem;

}


.footer-texts{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0.5rem;
}

.footer-text{
    text-align: center;
    color: white;
    font-size: 1rem;
    font-weight: 500;
}

.copyright-text{
    color: rgb(173, 187, 249);
}

@media only screen and (min-width: 768px) {
    .footer-texts{
        width: 100%;
        margin-left: 0rem;
        flex-direction: row;
        align-self: center;
        align-items: center;
        justify-content: center;
    }
    .footer-logos{
        width: 100%;
        flex-direction: row;
    }
    .footer-text {
        margin-left: 2rem;
    }
}

@media only screen and (min-width: 1024px) {
    .footer-container{
        height: 9rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .footer-logos{
        width: 45%;
        flex-direction: row;
        margin-top: 0;
    }
    .footer-texts{
        width: 45%;
        margin-left: 10%;
        display: flex;
        flex-direction: row;
        align-self: center;
        align-items: center;
        justify-content: flex-end;
    }

  
}


@media only screen and (min-width: 1216px) {
    .footer-logos{
        width: 40%;
    }
    .footer-texts{
        width: 45%;
        margin-left: 15%;
    }
}

#privacy-modal-trigger {
    cursor: pointer;
}