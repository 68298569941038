$primary: rgb(40, 78, 239);
$secondary: rgb(52, 204, 237);
$primary-accent: darken($primary, 10%);
$secondary-accent: darken($secondary, 10%);

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700;800&display=swap');
@import "./welcome-container.scss";
@import "./hero-image-container.scss";
@import "./hero-container.scss";
@import "./hero-bottom-container.scss";
@import "./card-container.scss";
@import "./form-container.scss";
@import "./footer-container.scss";
@import "./modal.scss";

html, body {
    height: 100%;
    width: 100%;
    background-color: $primary;
    margin: 0;
    font-family: 'Nunito', sans-serif;

}

*{
    box-sizing: border-box;
}

.blue-zone {
    margin: 0;
    width: 100%;
    height: fit-content;
    background: url('../images/ribbon.png') top left / 12rem auto no-repeat;
    background-color: $primary;
}

.white-zone {
    margin: 0;
    width: 100%;
    height: fit-content;
    background-color: white;
}

.flex-container {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.container {
    margin-left: 3rem;
    margin-right: 3rem;
}

@media only screen and (min-width: 1024px) {
    .container {
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

@media only screen and (min-width: 1216px) {
    .container {
        margin-left: 6rem;
        margin-right: 6rem;
    }
}

.text {
    margin: 0px;
    color: white;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
}

footer {
    background-color: #1a3bc9;
}