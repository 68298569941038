.cards-container {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

.card {
    box-shadow: 0px 0px 7px -1px #BDBDBD;
    -webkit-box-shadow: 0px 0px 7px -1px #BDBDBD;
    -moz-box-shadow: 0px 0px 7px -1px #BDBDBD;
    padding: 0.8rem 1.2rem;
    border-radius: 0.2rem;
    margin-top: 0;
    margin-bottom: 2rem;
    background-color: white;
}

.card:first-child {
    margin-top: -4rem;
}

.card-title {
    text-align: center;
    color: rgb(9, 25, 97);
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.card-text {
    text-align: center;
    color: rgb(9, 25, 97);
    font-size: 1.1rem;
    font-weight: 400;
    height: 5rem;
    margin-bottom: 2.5rem;
}

@media only screen and (min-width: 768px) {
    .card {
        margin-top: -3rem;
        margin-bottom: 5rem;
        padding: 2rem;
        height: fit-content;
    }
    .card-text {
        height: 5rem;
    }
}

@media only screen and (min-width: 1024px) {
    .cards-container {
        flex-direction: row;
        justify-content: space-between;
    }
    .card {
        width: 30%;
        margin-top: -4rem;
        margin-bottom: 0;
        padding: 2rem 2rem 3rem 2rem;
        height: fit-content;
    }
    .card-title {
        font-size: 1.4rem;
        line-height: 1.5rem;
        height: 2.8rem;
    }
    .card-text {
        text-align: center;
        color: rgb(9, 25, 97);
        font-size: 1.15rem;
        font-weight: 400;
        height: 9rem;
    }
}

@media only screen and (min-width: 1216px) {
    .card {
        width: 27%;
        padding: 2rem;
    }
}