.form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 27rem;
}


.form-container::before {
    content: '';
    position: absolute;
    left: 0;
    top: -27rem;
    height: 27rem;
    width: 100%;
    background: url('../images/form-image.png') center center / cover no-repeat;
    opacity: 0.7;
}


.form {
    background-color: rgb(255, 204, 250);
    width: 100%;
    z-index: 1;
    padding-bottom: 1rem;
}

.form-fields{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.form-title {
    color: rgb(9, 25, 97);
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-input {
    width: 100%;
    padding:1.2rem;
    border: none;
    border-radius: 0.8rem;
    margin-bottom: 1.2rem;
    font-size: 0.8rem;
    font-weight: 300;
}

.form-button{
    background-color: rgb(40, 78, 239);
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    text-align: center;
    width: 100%;
    font-size: 1.15rem;
    margin-bottom: 10%;
    font-weight: 600;
    text-align: center;
    color: white;
    border-radius: 0.7rem;
    border: none;
    transition: 0.2s;
}

.form-button:hover{
    background-color: $primary-accent;
    transition: 0.2s;
    cursor: pointer;
}

.form-button:focus{
    outline: none; 
}

.text-area {
    width: 100%;
    padding:1.2rem;
    height: 8rem;
    border: none;
    border-radius: 0.8rem;
    margin-bottom: 1.2rem;
    font-size: 0.8rem;
    font-weight: 300;
    resize: none;
}

::placeholder {
    font-family: 'Nunito', sans-serif;
    color: rgb(141, 141, 141);
    opacity: 1; /* Firefox */
    font-size: 0.8rem;
    font-weight: 300;
    text-align: left;
  }

form p {
    margin-top: 0;
}   

@media only screen and (min-width: 768px) {
    .form-fields{
        margin-right: auto;
        margin-left: auto;
        width: 70%;
    }

    .form-button{
        text-align: center;
        width: 100%;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 1024px) {
    .form-fields {
        margin-right: auto;
        margin-left: auto;
        width: 70%;
    }

    .form-button {
        text-align: center;
        width: 100%;
        margin-bottom: 5%;
    }

    .form-container {
        flex-direction: row;
        margin-top: 0;
    }

    .form-container::before {
        top: 0;
        width: 60%;
        height: 100%;
    }

    
    .form {
        margin-left: 45%;
        width: 60%;
        margin-top: 3.5rem;
        margin-right: 2%;
    }
    .form-fields{
        margin-left: 29%;
        width: 59%;
    }
    .form-title {
        text-align: left;
        font-size: 1.8rem;
        margin-left: 12%;
        margin-top: 3rem;
    }
    .form-button{
        width: auto;
        padding: 0.8rem 2rem;
    }
    .form-input {
        padding:1rem;
        margin-bottom: 1.3rem;
    }
    .text-area {
        height: 6rem;
    }
}


@media only screen and (min-width: 1216px) {
    .form-title {
        font-size: 2rem;
        margin-left: 14%;
    }

}