.hero-container{
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
}

@media only screen and (min-width: 1024px) {
    .hero-container{
        display: flex;
        flex-direction: row;
        margin-left: 6rem;
    }
}