.hero-image-container {
    position: relative;
    margin-top: 2.5rem;
    text-align: center;
}

.hero-image {
    position: relative;
}

.hero-image img {
    max-width: 100%;
}

.hero-image img:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
}

#hero-images-1 {
    animation: pulseSlightly 7s linear infinite;
}


#hero-images-2 {
    animation: pulseBigger 7s linear infinite;
}


#hero-lines {
    animation: pulse 7s linear infinite;
}

#hero-circles {
    animation: floatAround 7s linear infinite;
}



@media only screen and (min-width: 1024px) {
    .hero-image-container {
        width: 65%;
        margin: 0 0 0 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@keyframes floatAround {
    0% {
        -webkit-transform: scale(1) translate3d(1%, 1%, 0);
        transform: scale(1) translate3d(1%, 1%, 0);
    }
    50% {
        -webkit-transform: scale(0.95) translate3d(-1%, -1%, 0);
        transform: scale(0.95) translate3d(-1%, -1%, 0);
    }
    100% {
        -webkit-transform: scale(1) translate3d(1%, 1%, 0);
        transform: scale(1) translate3d(1%, 1%, 0);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.88);
        transform: scale(0.88);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulseSlightly {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulseBigger {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.85);
        transform: scale(0.85);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}