@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700;800&display=swap");

.welcome-container {
    width: 100%;
    padding-top: 6rem;
    text-align: center;
}

.title {
    margin: 0;
    margin-top: 2rem;
    color: white;
    font-weight: bold;
    font-size: 2.2rem;
    text-align: center;
}

.logo-image {
    width: 9rem;
}

.welcome-text-container {
    position: relative;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2.2rem;
    text-align: center;
}

.button {
    background-color: $secondary;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 0.7rem;
    width: 100%;
    transition: 0.2s;
}

.button:hover {
    background-color: $secondary-accent;
    transition: 0.2s;
    cursor: pointer;
}

.button:focus {
    outline: none;
}

@media only screen and (min-width: 768px) {
    .welcome-container {
        width: 100%;
    }
    .welcome-text-container {
        margin-top: 2rem;
        margin-bottom: 2.2rem;
        align-items: center;
    }

    .button {
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) {
    .welcome-container {
        width: 30%;
        margin: 0;
        padding: 0;
        text-align: left;

    }

    .welcome-text-container p {
        padding-left: 2rem;
    }

    .welcome-text-container::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: #ffffff;
    }
    
    .title {
        text-align: left;
    }

    .button {
        width: 14rem;
    }

    .welcome-text-container {
        text-align: left;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1215px) {
    .title {
        font-size: 1.8rem;
    }

    .welcome-text-container p {
        font-size: 1.1rem;
    }
}
